import { Box } from "@mui/material";
import { useEffect } from "react";
import NotFoundImage from "../../components/NotFound/NotFoundImage";
import CommonSubSubHeader from "../../shared/Components/Text/CommonSubSubHeader";
import { reloadFonts } from "../FontHelpers";

export default function DefaultErrorBoundary() {
  // Ensure fonts are loaded
  useEffect(() => reloadFonts(), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <NotFoundImage />
      <Box height="50px" />
      <CommonSubSubHeader>An error has occured.</CommonSubSubHeader>
    </Box>
  );
}
