import { Box } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumb/Breadcrumbs";
import Navbar from "../components/Navigation/Navbar";
import { navbarHeight } from "../components/Navigation/NavbarConstants";
import { useDocumentTitle } from "../shared/Context/DocumentTitleContext";
import { useScrollPositionContext } from "../shared/Context/ScrollPositionContext";
import getBuildHash from "./BuildHelpers";
import { reloadFonts } from "./FontHelpers";
import HideRecaptchaBadge from "../components/Contact/HideRecaptchaBadge";

/**
 * The primary application, this is rendered inside of all wrappers
 * so that anything below this in the DOM can access all contexts declared.
 */
export default function App() {
  // Context
  const { setPrimaryScrollViewRef } = useScrollPositionContext();
  const { setDocumentTitle } = useDocumentTitle();

  // Effects
  useEffect(() => {
    setDocumentTitle("Nathan Cheshire");
    reloadFonts();

    console.debug("Website built with <3 by Nate Cheshire");
    console.debug(`Website build: ${getBuildHash()}`);
  }, []);

  return (
    <Box
      ref={setPrimaryScrollViewRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "white",
        position: "relative",
        scrollBehavior: "auto",
        overflowY: "overlay",
        top: 0,
        right: 0,
        "&::-webkit-scrollbar": {
          width: `8px`,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "black",
          borderRadius: `4px`,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        scrollbarWidth: "thin",
        scrollbarColor: `black transparent`,
      }}
    >
      <HideRecaptchaBadge />
      <Breadcrumbs />
      <Navbar />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: `calc(100% - ${navbarHeight}px)`,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
