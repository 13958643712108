import { Typography } from "@mui/material";
import { verticalPadding } from "../../../components/Blogs/BlogComponents/BlogConstants";
import useScalableSizes from "../../Hooks/useScalableSizes";
import { generalMaxWidth } from "../../Constants/CommonConstants";

interface CommonDescriptionProps {
  children?: React.ReactNode;
  textAlign?: string;
  width?: string;
  maxWidth?: string;
  fontFamily?: string;
}

export default function CommonDescription({
  children,
  width = "auto",
  maxWidth = generalMaxWidth,
  fontFamily = "Roboto",
  textAlign = "start",
}: CommonDescriptionProps) {
  const { descriptionTextSize } = useScalableSizes();

  return (
    <Typography
      component={"span"}
      sx={{
        maxWidth: maxWidth,
        fontFamily: fontFamily,
        fontSize: descriptionTextSize,
        color: "#454545",
        textAlign: textAlign,
        width: width,
        marginY: `${verticalPadding}px`,
      }}
    >
      {children}
    </Typography>
  );
}
