import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useModal } from "query-string-modals";
import { useState } from "react";
import { useAppDispatch } from "../../redux/ReduxHooks";
import { resetAudioSlice } from "../../redux/audioSlice";
import { resetChooseAlbumSlice } from "../../redux/chooseAlbumSlice";
import { resetEmailSlice } from "../../redux/emailSlice";
import { resetExportMapSlice } from "../../redux/exportMapSlice";
import { resetExportTuneSlice } from "../../redux/exportTuneSlice";
import { resetLocationSlice } from "../../redux/locationSlice";
import { resetMapSlice } from "../../redux/mapSlice";
import { resetMapVisualizationSlice } from "../../redux/mapVisualizationSlice";
import { resetMetaSlice } from "../../redux/metaSlice";
import { resetSearchAlbumsSlice } from "../../redux/searchAlbumsSlice";
import { resetSpotifyAuthenticationSlice } from "../../redux/spotifyAuthenticationSlice";
import { reduxSlices } from "../../redux/store";
import { resetTuneFeatureSlice } from "../../redux/tuneFeatureSlice";
import StandardModal from "../../shared/Components/Modal/StandardModal";
import { useAlert } from "../../shared/Context/AlertContext";
import { BooleanTag } from "./BooleanTag";
import ResetSliceCheckbox from "./ResetSliceCheckbox";
import { resetPreferenceSlice } from "../../redux/preferenceSlice";

export default function ResetReduxModal() {
  // Redux
  const dispatch = useAppDispatch();

  // Query String Modals
  const { closeModal } = useModal();

  // Context
  const { showInfoAlert } = useAlert();

  // State
  const [checks, setChecks] = useState<BooleanTag[]>(constructCheckedStates());
  const [allSelected, setAllSelected] = useState<boolean>(false);

  function setSliceChecked(sliceName: string, checked: boolean) {
    const updatedChecks: BooleanTag[] = [];
    checks.forEach((check) => {
      if (check.name === sliceName) {
        updatedChecks.push({ name: sliceName, checked: checked });
      } else {
        updatedChecks.push(check);
      }
    });
    setChecks(updatedChecks);
  }

  function resetSlice(name: string) {
    if (name === "location") {
      dispatch(resetLocationSlice());
    } else if (name === "map") {
      dispatch(resetMapSlice());
    } else if (name === "exportMap") {
      dispatch(resetExportMapSlice());
    } else if (name === "meta") {
      dispatch(resetMetaSlice());
    } else if (name === "spotifyAuthentication") {
      dispatch(resetSpotifyAuthenticationSlice());
    } else if (name === "audio") {
      dispatch(resetAudioSlice());
    } else if (name === "searchAlbums") {
      dispatch(resetSearchAlbumsSlice());
    } else if (name === "chooseAlbum") {
      dispatch(resetChooseAlbumSlice());
    } else if (name === "tuneFeatures") {
      dispatch(resetTuneFeatureSlice());
    } else if (name === "exportTune") {
      dispatch(resetExportTuneSlice());
    } else if (name === "email") {
      dispatch(resetEmailSlice());
    } else if (name === "mapVisualizations") {
      dispatch(resetMapVisualizationSlice());
    } else if (name === "preference") {
      dispatch(resetPreferenceSlice());
    }
  }

  function onResetClicked() {
    const slicesToReset = checks.filter((check) => check.checked);
    const length = slicesToReset.length;
    if (length < 1) return;
    slicesToReset
      .filter((check) => check.checked)
      .forEach((slice) => resetSlice(slice.name));
    closeModal();
    showInfoAlert(`Reset ${length} slice${length === 1 ? "" : "s"}`);
  }

  function constructCheckedStates(initialState = false) {
    return Object.keys(reduxSlices)
      .sort()
      .map((sliceName) => {
        return {
          name: sliceName,
          checked: initialState,
        };
      });
  }

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        width: "100%",
        paddingX: "20px",
        marginTop: "20px",
      }}
    >
      <FormControlLabel
        sx={{
          userSelect: "none",
        }}
        control={
          <Checkbox
            checked={allSelected}
            onChange={(event) => {
              const newState = event.target.checked;
              setAllSelected(newState);
              setChecks(constructCheckedStates(newState));
            }}
          />
        }
        label={"Select all"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "3px",
          borderRadius: "10px",
          backgroundColor: "#8F8F8F",
          marginY: "10px",
        }}
      />
      {checks.map((check) => (
        <ResetSliceCheckbox
          key={check.name}
          booleanTag={check}
          setSliceChecked={setSliceChecked}
        />
      ))}
    </Box>
  );

  return (
    <StandardModal
      title="Reset Redux slices"
      cancelButtonText={"Close"}
      cancelButtonAction={() => closeModal()}
      descrpitionText="Note, resetting specific slices and not other ones could lead to incorrect logic and buggy behavior. Make sure you know what you're doing."
      primaryButtonText="Reset"
      primaryButtonAction={onResetClicked}
      maxWidth="400px"
    >
      {content}
    </StandardModal>
  );
}
