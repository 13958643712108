import { Typography } from "@mui/material";
import getBuildHash from "../../app/BuildHelpers";
import { Link } from "react-router-dom";
import { orange } from "../../shared/Constants/Colors";
import { joinUrlParts } from "../../shared/Helpers/GeneralHelpers";

export default function CommitHashLink() {
  // Constants
  const hash = getBuildHash() ?? "localhost";
  const linkPrefix = "https://github.com/NathanCheshire/nathan-cheshire/commit";

  return (
    <Link to={joinUrlParts(linkPrefix, hash)}>
      <Typography
        sx={{
          marginTop: "50px",
          color: "white",
          fontWeight: "bold",
          fontFamily: "Prompt",
          fontSize: "15px",
          paddingBlock: "5px",
          transition: "all 0.15s linear",
          "&:hover": {
            color: orange,
          },
        }}
      >
        {hash}
      </Typography>
    </Link>
  );
}
