import { Box, Slide } from "@mui/material";
import { ReactNode } from "react";
import { useInView } from "react-intersection-observer";

interface Props {
  children?: ReactNode;
  justification?: "center" | "start" | "end";
  maxWidth?: string;
  customBoxJustification?: string;
  gap?: string;
}

export default function SectionContainer({
  children,
  justification = "start",
  maxWidth = "650px",
  customBoxJustification = undefined,
  gap = "0px",
}: Props) {
  // Constants
  const lengthPercent = 75;
  const borderLen = 10;
  const borderRadius = 2 * borderLen;
  const borderColor = "#FBAE3CCF";

  // Hooks
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const leftJustificationBorder = {
    content: '""',
    position: "absolute",
    top: -borderLen,
    left: -borderLen,
    width: `calc(${lengthPercent}% + ${borderLen}px)`,
    height: `calc(${lengthPercent}% + ${borderLen}px)`,
    borderLeft: `${borderLen}px solid ${borderColor}`,
    borderTop: `${borderLen}px solid ${borderColor}`,
    pointerEvents: "none",
    borderTopLeftRadius: `${borderRadius}px`,
    borderBottomLeftRadius: `${borderRadius / 2}px`,
    borderTopRightRadius: `${borderRadius / 2}px`,
  };

  const centerJustificationBorder = {
    content: '""',
    position: "absolute",
    top: -borderLen,
    left: -borderLen,
    width: `calc(100% + ${borderLen * 2}px)`,
    height: `calc(25% + ${borderLen}px)`,
    borderLeft: `${borderLen}px solid ${borderColor}`,
    borderRight: `${borderLen}px solid ${borderColor}`,
    borderTop: `${borderLen}px solid ${borderColor}`,
    pointerEvents: "none",
    borderTopLeftRadius: `${borderRadius}px`,
    borderBottomLeftRadius: `${borderRadius / 2}px`,
    borderTopRightRadius: `${borderRadius}px`,
    borderBottomRightRadius: `${borderRadius / 2}px`,
  };

  const rightJustificationBorder = {
    content: '""',
    position: "absolute",
    top: -borderLen,
    right: -borderLen,
    width: `calc(${lengthPercent}% + ${borderLen}px)`,
    height: `calc(${lengthPercent}% + ${borderLen}px)`,
    borderRight: `${borderLen}px solid ${borderColor}`,
    borderTop: `${borderLen}px solid ${borderColor}`,
    pointerEvents: "none",
    borderTopRightRadius: `${borderRadius}px`,
    borderTopLeftRadius: `${borderRadius / 2}px`,
    borderBottomRightRadius: `${borderRadius / 2}px`,
  };

  function getJustificationBorder() {
    if (justification === "center") {
      return centerJustificationBorder;
    } else if (justification === "start") {
      return leftJustificationBorder;
    } else {
      return rightJustificationBorder;
    }
  }

  function getBoxShadowStyling() {
    if (justification === "start") {
      return "12px 12px 12px rgba(0, 0, 0, 0.1), 0px 5px 10px #F0F0F0";
    } else if (justification === "end") {
      return "-12px 12px 12px rgba(0, 0, 0, 0.1), -5px 0px 10px #F0F0F0";
    } else {
      return "0px 12px 12px rgba(0, 0, 0, 0.1), 0px 5px 5px #F0F0F0";
    }
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: customBoxJustification ?? justification,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Slide direction="up" in={inView} timeout={500}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              padding: "25px",
              borderRadius: `${borderRadius}px`,
              boxShadow: getBoxShadowStyling(),
              backgroundColor: "white",
              maxWidth: maxWidth,
              position: "relative",
              "&::before": getJustificationBorder(),
              gap: gap,
            }}
          >
            {children}
          </Box>
        </Slide>
      </Box>
    </Box>
  );
}
