import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import NavigationArrows from "../../../../components/NavigationArrows/NavigationArrows";
import Footer from "../../../../components/Footer/Footer";
import { useDocumentTitle } from "../../../Context/DocumentTitleContext";
import useScalableSizes from "../../../Hooks/useScalableSizes";
import { useCommonRouteContainerVerticalPadding } from "./useCommonRouteContainerVerticalPadding";

interface CommonRouteContainerProps {
  children?: React.ReactNode;
  titlePrefix?: string;
  gap?: string;
  display?: string;
  width?: string;
  paddingY?: string;
  constrainToMiddleOnExcessSpace?: boolean;
}

export default function CommonRouteContainer({
  children,
  titlePrefix,
  gap = "50px",
  display = "flex",
  width,
  paddingY = "determine",
  constrainToMiddleOnExcessSpace = true,
}: CommonRouteContainerProps) {
  // Constants
  const fadeInDurationMs = 450;
  const fadeInDelayMs = 10;

  // Hooks
  const usagePaddingY = useCommonRouteContainerVerticalPadding(paddingY);

  // State
  const [fadeInCompleted, setFadeInCompleted] = useState<boolean>(false);

  // Context
  const { setDocumentTitlePrefix } = useDocumentTitle();
  const { primaryContainerWidth } = useScalableSizes();

  // Effects
  useEffect(() => {
    if (titlePrefix) setDocumentTitlePrefix(titlePrefix);
  }, []);

  useEffect(() => {
    let timeoutId = setTimeout(() => setFadeInCompleted(true), fadeInDelayMs);
    return () => {
      clearTimeout(timeoutId);
      setFadeInCompleted(false);
    };
  }, [fadeInDurationMs]);

  const primaryChildren = (
    <>
      <NavigationArrows />
      {children}
    </>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      }}
    >
      {constrainToMiddleOnExcessSpace ? (
        <Box
          sx={{
            display: display,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: width ?? primaryContainerWidth,
            gap: gap,
            transition: `opacity ${fadeInDurationMs}ms ease-in-out`,
            opacity: fadeInCompleted ? 1 : 0,
            flex: 1,
            paddingY: usagePaddingY,
            maxWidth: "1000px",
          }}
        >
          {primaryChildren}
        </Box>
      ) : (
        primaryChildren
      )}
      <Box
        sx={{
          display: "flex",
          flex: 0,
          width: "100%",
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
}
