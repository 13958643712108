import { Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { join } from "../../shared/Helpers/GeneralHelpers";
import {
  maxOpacity,
  minOpacity,
} from "../CartographyCrafter/helpers/color/OpacityConstants";
import { navbarColor, navbarHeight } from "../Navigation/NavbarConstants";
import Crumb from "./Crumb";
import CrumbSeparator from "./CrumbSeparator";

export default function Breadcrumbs() {
  // Constants
  const maxAnimationTime = 0.3;

  // State
  const [opacity, setOpacity] = useState<number>(minOpacity);
  const [animationTime, setAnimationTime] = useState<number>(maxAnimationTime);

  // Hooks
  const location = useLocation();

  // Memos
  const pathnameParts = useMemo(() => {
    return location.pathname.split("/").filter((part) => !!part);
  }, [location.pathname]);

  // Effects
  useEffect(() => {
    const canShowCrumbs = pathnameParts.length > 1;
    setOpacity(canShowCrumbs ? maxOpacity : minOpacity);
    setAnimationTime(canShowCrumbs ? maxAnimationTime : 0);
  }, [location]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: `${navbarHeight}px`,
        left: 0,
        zIndex: 1000,
        backgroundColor: navbarColor,
        borderRadius: "0 0 8px 0px",
        height: "45px",
        width: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingX: "20px",
        opacity: opacity,
        transition: `opacity ${animationTime}s linear`,
      }}
    >
      {pathnameParts.length > 1 &&
        pathnameParts.map((pathPart, index) => {
          const to = join(pathnameParts, index + 1);

          return (
            <React.Fragment key={index}>
              <Crumb label={pathPart} to={to} />
              {index < pathnameParts.length - 1 && <CrumbSeparator />}
            </React.Fragment>
          );
        })}
    </Box>
  );
}
