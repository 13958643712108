import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, IconButton, Zoom } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { offBlack, orange } from "../../shared/Constants/Colors";
import { commonBoxShadow } from "../../shared/Constants/StylingConstants";
import { useScrollPositionContext } from "../../shared/Context/ScrollPositionContext";
import { useMinWidth } from "../../shared/Hooks/useMinWidth";
import { footerHeight } from "../Footer/FooterConstants";
import { MenuItem } from "../Navigation/MenuItem";
import { lastMenuItem, nextMenuItem } from "../Navigation/MenuItems";
import NavigationArrow from "./NavigationArrow";

export default function NavigationArrows() {
  // Constants
  const showButtonWhenPastNumPixels = 100;
  const buttonId = "backToTopButton";

  // State
  const [buttonHeight, setButtonHeight] = useState<number>(0);
  const [next, setNext] = useState<MenuItem | undefined>(undefined);
  const [last, setLast] = useState<MenuItem | undefined>(undefined);

  // Hooks
  const location = useLocation();
  const canShowNavigationArrows = useMinWidth(600);

  // Context
  const { resetScrollPosition, currentScrollPosition } =
    useScrollPositionContext();

  // Effects
  useEffect(() => {
    const buttonElement = document.getElementById(buttonId);
    if (!buttonElement) return;
    setButtonHeight(buttonElement.clientHeight);
  }, []);

  useEffect(() => {
    const previous = lastMenuItem(location.pathname);
    const next = nextMenuItem(location.pathname);
    setLast(previous);
    setNext(next);
  }, [location.pathname]);

  return (
    <Box>
      <Zoom in={currentScrollPosition > showButtonWhenPastNumPixels}>
        <Box
          sx={{
            position: "fixed",
            zIndex: 10,
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            marginX: "10px",
            borderRadius: "200px",
            gap: "20px",
            right: 10,
            bottom: `calc(${footerHeight}px / 2 - ${buttonHeight}px / 2 - 2px)`,
          }}
          id={buttonId}
        >
          <Box
            sx={{
              backgroundColor: orange,
              borderRadius: "200px",
              boxShadow: commonBoxShadow,
              opacity: 0.5,
              transition: "opacity 0.3s linear",
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            <IconButton onClick={resetScrollPosition}>
              <KeyboardArrowUpIcon
                sx={{
                  color: offBlack,
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Zoom>
      {canShowNavigationArrows && (
        <>
          {next && (
            <NavigationArrow
              height={buttonHeight}
              bottomOffset={50}
              linkTo={next?.urlPath}
              rotationDegrees={90}
            />
          )}
          {last && (
            <NavigationArrow
              height={buttonHeight}
              bottomOffset={100}
              linkTo={last?.urlPath}
              rotationDegrees={270}
            />
          )}
        </>
      )}
    </Box>
  );
}
