import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./app/WebsiteRoutes";
import "./index.css";

const htmlRoot = document.getElementById("root");
if (!htmlRoot) throw Error("No root element found");
const reactRoot = ReactDOM.createRoot(htmlRoot);

reactRoot.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
