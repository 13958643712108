import emailjs from "@emailjs/browser";
import { Box, Typography } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../redux/ReduxHooks";
import {
  resetEmailSlice,
  setContent,
  setEmail,
  setName,
} from "../../redux/emailSlice";
import { RootState } from "../../redux/store";
import CommonButton from "../../shared/Components/Buttons/CommonButton";
import HoverableLink from "../../shared/Components/Links/HoverableLink";
import { evenMoreOffBlack } from "../../shared/Constants/Colors";
import {
  emailJsPublicKey,
  emailJsServiceId,
  emailJsTemplateId,
} from "../../shared/Constants/EmailJsConstants";
import { useAlert } from "../../shared/Context/AlertContext";
import { verticalPadding } from "../Blogs/BlogComponents/BlogConstants";
import ContactField from "./ContactField/ContactField";

interface Props {
  hoverEffect?: boolean;
  onEmailSuccessfullySent?: () => void;
}

export default function ContactViaEmail({
  hoverEffect = true,
  onEmailSuccessfullySent,
}: Props) {
  // Constants
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validDataButtonText = "Send Email";

  // Hooks
  const { executeRecaptcha } = useGoogleReCaptcha();

  const sendEmailSchema = yup.object().shape({
    content: yup
      .string()
      .required("Email Content Required")
      .test(
        "valid-email-content",
        "Valid Content Required",
        (content) => (content?.trim().length ?? 0) > 0
      ),
    email: yup
      .string()
      .required("Email Required")
      .matches(emailRegex, "Valid Email Required"),
    emailName: yup
      .string()
      .required("Name Required")
      .test(
        "valid-name",
        "Valid Name Required",
        (name) => (name?.trim().length ?? 0) > 0
      ),
  });

  // Redux
  const dispatch = useAppDispatch();

  const emailName = useAppSelector((state: RootState) => state.email.name);
  const email = useAppSelector((state: RootState) => state.email.email);
  const content = useAppSelector((state: RootState) => state.email.content);

  // Context
  const { showInfoAlert, showErrorAlert } = useAlert();

  function getSendEmailButtonText() {
    const data = { emailName, email, content };

    try {
      sendEmailSchema.validateSync(data);
      return validDataButtonText;
    } catch (error: any) {
      return error instanceof yup.ValidationError ? error.message : "Error";
    }
  }

  function sendEmailButtonDisabled() {
    return getSendEmailButtonText() !== validDataButtonText;
  }

  function getEmailHeleprText(email: string) {
    if (emailRegex.test(email)) return "";
    return "Valid email required";
  }

  function onEmailSent() {
    showInfoAlert("Submitted email");
    onEmailSuccessfullySent?.();
    dispatch(resetEmailSlice());
  }

  async function handleSendEmailClick() {
    if (!executeRecaptcha) return;
    const gcaptcha = await executeRecaptcha();

    const params = {
      from_name: emailName,
      email: email,
      message: content,
      "g-recaptcha-response": gcaptcha,
    };

    emailjs
      .send(emailJsServiceId, emailJsTemplateId, params, emailJsPublicKey)
      .then((_result: any) => {
        onEmailSent();
      })
      .catch((error: any) => {
        console.error(error);
        showErrorAlert("Failed to submit");
      });
  }

  return (
    <Box
      sx={{
        transition: "all 0.15s linear",
        "&:hover": {
          boxShadow: hoverEffect
            ? "12px 12px 12px rgba(0, 0, 0, 0.1), -10px -10px 10px #F0F0F0"
            : "",
        },
        paddingX: "15px",
        paddingY: "40px",
        borderRadius: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "300px",
          maxWidth: "90%",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            paddingLeft: "5px",
            color: evenMoreOffBlack,
            fontFamily: "Prompt",
            fontWeight: "bold",
            fontSize: "22px",
            textAlign: "center",
          }}
        >
          Contact form
        </Typography>
        <ContactField
          labelText={"Your name"}
          value={emailName}
          setValue={(newValue: string) => dispatch(setName(newValue))}
        />
        <ContactField
          labelText={"Your email"}
          value={email}
          setValue={(newValue: string) => dispatch(setEmail(newValue))}
          getHelperText={getEmailHeleprText}
        />
        <ContactField
          labelText={"Email content"}
          value={content}
          setValue={(newValue: string) => dispatch(setContent(newValue))}
          lines={8}
        />
        <CommonButton
          text={getSendEmailButtonText()}
          disabled={sendEmailButtonDisabled()}
          onClick={handleSendEmailClick}
        />
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            color: "#626262",
            textAlign: "start",
            marginY: `${verticalPadding}px`,
          }}
        >
          This site is protected by reCAPTCHA and the Google{" "}
          <HoverableLink linkTo="https://policies.google.com/privacy">
            Privacy Policy
          </HoverableLink>{" "}
          and{" "}
          <HoverableLink linkTo="https://policies.google.com/terms">
            Terms of Service
          </HoverableLink>{" "}
          apply.
        </Typography>
      </Box>
    </Box>
  );
}
