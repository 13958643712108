import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { ModalProvider } from "query-string-modals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PersistGateLoading from "../redux/PersistGate/PersistGateLoading";
import { persistor, store } from "../redux/store";
import { AlertProvider } from "../shared/Context/AlertContext";
import AudioProvider from "../shared/Context/AudioContext";
import DocumentTitleProvider from "../shared/Context/DocumentTitleContext";
import ScriptLoaderProvider from "../shared/Context/ScriptLoaderContext";
import ScrollPositionProvider from "../shared/Context/ScrollPositionContext";
import SpotifyTokenProvider from "../shared/Context/SpotifyTokenContext";
import StepProvider from "../shared/Context/StepContext";
import App from "./App";
import { modals } from "./AppModals/AppModals";
import FallbackModal from "./AppModals/FallbackModal";
import { muiTheme } from "./Theme";

export default function AppWrappers() {
  return (
    <ThemeProvider theme={muiTheme}>
      <AlertProvider>
        <DocumentTitleProvider>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              {(persistenceLoaded) => {
                if (persistenceLoaded) {
                  return (
                    <StepProvider>
                      <SpotifyTokenProvider>
                        <ScriptLoaderProvider>
                          <AudioProvider>
                            <CssBaseline />
                            <ScrollPositionProvider>
                              <ModalProvider
                                modals={modals}
                                removeModalIdIfNotFound={false}
                                modalIdNotFoundFallback={<FallbackModal />}
                              >
                                <App />
                              </ModalProvider>
                            </ScrollPositionProvider>
                          </AudioProvider>
                        </ScriptLoaderProvider>
                      </SpotifyTokenProvider>
                    </StepProvider>
                  );
                } else {
                  return <PersistGateLoading />;
                }
              }}
            </PersistGate>
          </Provider>
        </DocumentTitleProvider>
      </AlertProvider>
    </ThemeProvider>
  );
}
