import { useMinWidth } from "./useMinWidth";

export const useCondition = (
  transitionPoint: number,
  trueCondition: string,
  falseCondition: string
): string => {
  const canShowRow = useMinWidth(transitionPoint);
  return canShowRow ? trueCondition : falseCondition;
};
