import { Box } from "@mui/material";
import { useCondition } from "../../shared/Hooks/useCondition";
import { useMinWidth } from "../../shared/Hooks/useMinWidth";
import { useRowColumn } from "../../shared/Hooks/useRowColumn";
import { navbarColor } from "../Navigation/NavbarConstants";
import { footerHeight } from "./FooterConstants";
import FooterLaunch from "./FooterLaunch";
import FooterText from "./FooterText";

export default function Footer() {
  // Constants
  const transitionPoint = 750;

  // Hooks
  const canShowRow = useMinWidth(transitionPoint);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: useRowColumn(transitionPoint),
          alignItems: "center",
          justifyContent: useCondition(
            transitionPoint,
            "space-between",
            "center"
          ),
          width: "100%",
          height: `${footerHeight}px`,
          bottom: 0,
          backgroundColor: navbarColor,
          padding: "20px",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: useCondition(transitionPoint, "column", "row"),
            alignItems: "start",
            justifyContent: "center",
            gap: canShowRow ? "5px" : "25px",
          }}
        >
          <FooterLaunch text="EULA" link="/eula" />
          <FooterLaunch text="Credits" link="/credits" />
        </Box>
        <FooterText>
          Copyright {new Date().getFullYear()}. Natche Group, LLC. All rights
          reserved.
        </FooterText>
        <Box />
      </Box>
    </Box>
  );
}
