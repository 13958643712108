import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import ForEmployers from "../components/ForEmployers/ForEmployers";
import { viewLicenseAgreementUrlSuffix } from "../components/Services/ServiceConstants";
import PersistGateLoading from "../redux/PersistGate/PersistGateLoading";
import AppWrappers from "./AppWrapers";
import DefaultErrorBoundary from "./ErrorBoundaries/DefaultErrorBoundary";

// Lazy loaded components
const BlogPost = lazy(
  () =>
    import("../components/Blogs/BlogPost") as Promise<{
      default: React.ComponentType<any>;
    }>
);
const Blogs = lazy(() => import("../components/Blogs/Blogs"));
const Contact = lazy(() => import("../components/Contact/Contact"));
const Credits = lazy(() => import("../components/Credits/Credits"));
const Eula = lazy(() => import("../components/Eula/Eula"));
const GitHub = lazy(() => import("../components/GitHub/GitHub"));
const Home = lazy(() => import("../components/Home/Home"));
const LinkedInRedirector = lazy(
  () => import("../components/LinkedIn/LinkedInRedirector")
);
const MapboxMap = lazy(() => import("../components/Maps/Mapbox/MapboxMap"));
const NotFound = lazy(() => import("../components/NotFound/NotFound"));
const Todo = lazy(() => import("../components/Todo/Todo"));
const Services = lazy(() => import("../components/Services/Services"));
const SpotifyAuthenticationCallback = lazy(
  () =>
    import(
      "../components/TunePosters/Components/Spotify/SpotifyAuthenticationCallback"
    )
);
const WidgetContainer = lazy(
  () =>
    import("../components/Widgets/WidgetContainer") as Promise<{
      default: React.ComponentType<any>;
    }>
);
const Widgets = lazy(() => import("../components/Widgets/Widgets"));
const LicenseAgreementPreview = lazy(
  () => import("../components/Services/LicenseAgreementPreview")
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppWrappers />,
    errorElement: <DefaultErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Navigate to="/home" replace />,
      },
      {
        path: "/home",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "/services",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <Services />
          </Suspense>
        ),
        children: [
          {
            path: `/services/${viewLicenseAgreementUrlSuffix}`,
            element: (
              <Suspense fallback={<PersistGateLoading />}>
                <LicenseAgreementPreview />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "/widgets",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <Widgets />
          </Suspense>
        ),
      },
      {
        path: "/widgets/:widgetId",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <WidgetContainer />
          </Suspense>
        ),
      },
      {
        path: "/eula",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <Eula />
          </Suspense>
        ),
      },
      {
        path: "/blogs",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <Blogs />
          </Suspense>
        ),
      },
      {
        path: "/blogs/:blogId",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <BlogPost />
          </Suspense>
        ),
      },
      {
        path: "/contact",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <Contact />
          </Suspense>
        ),
      },
      {
        path: "/credits",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <Credits />
          </Suspense>
        ),
      },
      {
        path: "/github",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <GitHub />
          </Suspense>
        ),
      },
      {
        path: "/map",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <MapboxMap />
          </Suspense>
        ),
      },
      {
        path: "/for-employers",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <ForEmployers />
          </Suspense>
        ),
      },
      {
        path: "/spotify-authentication-callback",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <SpotifyAuthenticationCallback />
          </Suspense>
        ),
      },
      {
        path: "/linkedin",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <LinkedInRedirector />
          </Suspense>
        ),
      },
      {
        path: "/todo",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <Todo />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: (
          <Suspense fallback={<PersistGateLoading />}>
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
]);
