import { Typography } from "@mui/material";
import useScalableSizes from "../../Hooks/useScalableSizes";
import { evenMoreOffBlack } from "../../Constants/Colors";

interface Props {
  children?: React.ReactNode | string;
  color?: string;
}

export default function CommonSubSubHeader({
  children,
  color = evenMoreOffBlack,
}: Props) {
  const { subSubHeaderTextSize } = useScalableSizes();

  return (
    <Typography
      sx={{
        fontFamily: "Prompt",
        fontWeight: "bold",
        fontSize: subSubHeaderTextSize,
        color: color,
        textAlign: "center",
      }}
    >
      {children}
    </Typography>
  );
}
